import { Info24Regular } from '@fluentui/react-icons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { Button } from '../button';

export type InformationDialogProps = {
  title?: string;
  variant?: 'default' | 'error';
  icon?: React.ReactNode;
  open?: boolean;
  information?: string;
  onClose?: () => void;
};

export const InformationDialog = ({
  title,
  variant = 'default',
  icon,
  open,
  information,
  onClose,
}: InformationDialogProps) => {
  const theme = useTheme();
  return (
    <Dialog
      open={!!open}
      PaperProps={{
        sx: {
          minWidth: '26rem',
          maxWidth: '42rem',
          minHeight: '4rem',
          maxHeight: '30rem',
        },
      }}
    >
      <DialogTitle
        sx={{
          ...(variant === 'error' && {
            backgroundColor: theme.palette.warning[50],
          }),
        }}
      >
        {icon ?? <Info24Regular />}
        {title ?? (variant === 'error' ? 'Error' : 'Information')}
      </DialogTitle>
      <DialogContent>
        <Typography variant='body1'>{information}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InformationDialog;
